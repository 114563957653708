import { Controller } from "stimulus"
import { TIMEOUT_MESSAGES } from './messages.js'

export default class extends Controller {
  static targets = [ 
		"ai_type", "destination", 
		"format", "style", "time",
		"keyword_1", "keyword_2", "keyword_3", "keyword_4",
		"emoji", "call_action", "add_desc",
		"container" ]
		
  static values = {
    url: String
  }
    
  initialize() {
    $(this.ai_typeTarget).focus()
    this.index = 0
    this.answer = ""
  }
	
  typewriter() {
		if (this.index < this.answer.length) {
				this.containerTarget.innerHTML += this.answer.charAt(this.index);
				this.index++;
		    setTimeout(this.typewriter.bind(this), 3);
		}
  }
	
  autoSize(event) {
	  var el = $(event.target)[0];
	  el.style.height = 0;
	  el.style.height = (el.scrollHeight) + "px";
  }
	
  clear(event) {
		$(event.target).closest('.inputs').find('input, textarea').not('input[type="submit"]').val('');
		$(event.target).closest('.inputs').find('input, textarea').first().focus();
		$("#container")[0].innerHTML = '';
		$("#counter").text('0 words');
		$([document.documentElement, document.body]).animate({
			scrollTop: $(event.target).closest('.inputs').offset().top
		}, 1000);  
  }
	
  copy(event) {
		var $temp = $("<textarea>");
		$("body").append($temp);
		$temp.val($(event.target).parent().nextAll('div:first').text()).select();
		document.execCommand("copy");
		$temp.remove();
		$('.flashes').show();
		setTimeout(() => { $('.flashes').hide()} , 2000);
  }
	
	countWords(s) {
		s = s.replace(/(^\s*)|(\s*$)/gi,"");
		s = s.replace(/[ ]{2,}/gi," ");
		s = s.replace(/\n /,"\n"); 
		return s.split(' ').filter(function(str){ return str!=""; }).length;
	}
	
	generate(event) {
		event.preventDefault();
		window.loader = $("#dialog-loader").dialog({
		    dialogClass: "no-close",
		    modal: true
		});
		setTimeout(() => { $('.ui-widget-overlay').css({opacity:'0.75'}); $('.ui-widget-overlay').css({height: $( document ).height() + 'px'})}, 50);
		var input_data = { 
			authenticity_token: $('meta[name="csrf-token"]').attr('content'),
		  ai_type: this.ai_typeTarget.value, 
			destination: this.destinationTarget.value,
			style: this.styleTarget.value,
			fmt: this.formatTarget.value,
			time: this.timeTarget.value,
			keyword_1: this.keyword_1Target.value,
			keyword_2: this.keyword_2Target.value,
			keyword_3: this.keyword_3Target.value,
			keyword_4: this.keyword_4Target.value,
			emoji: $('input[name="emoji"]:checked').val(),
			call_action: $('input[name="call_action"]:checked').val(),
			add_desc: $('input[name="add_desc"]:checked').val(),
		  content: this.containerTarget.textContent,
      model: $('input[name="model"]:checked').val(),      
      apply_kb: $('input[name="content[apply_kb]"]:checked').val()
		}
		$.ajax({
		  context: this,
		  async: true,
		  type: "POST",
		  url: this.urlValue,
		  data: input_data,
		  dataType: 'json',
		  timeout: 120000
		}).done(function(data) {
	  		window.loader.dialog('close'); 
	  		if (typeof data[0] === 'number') {
					if(data[0] == 0)
	  				setTimeout(() => { alert("Smth went wrong. Please try again later! Error type:\n" + data[1])}, 500);
					else
						setTimeout(() => { alert(data[1])}, 500);
	  		} else { 
					if ($("#container").text().length == 0) {
						this.index = 0;
						this.answer = data[0] + "\n";
					} else {
						this.index = $("#container").text().length;
						this.answer += data[0] + "\n";
					}
					$("#counter").text(this.countWords(this.answer) + ' words');
					this.typewriter();
	  		}
		}).fail(function( jqXHR, textStatus ) {
	  	  window.loader.dialog('close');
				alert( TIMEOUT_MESSAGES[Math.floor(Math.random()*TIMEOUT_MESSAGES.length)] );
		});
	}
}