// Load Active Admin's styles into Webpacker,
// see `active_admin.scss` for customization.
// https://github.com/rails/webpacker/issues/1839
const importAll = (r) => r.keys().map(r)

importAll(require.context('../images', false, /\.(png|jpe?g|svg)$/));

import "../stylesheets/active_admin";

import "@activeadmin/activeadmin";
import "activeadmin_addons"

import "activeadmin_addons";
import "active_material";

import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/700.css";
import "@fortawesome/fontawesome-free/css/all.css";

import "chartkick/chart.js"
import ahoy from "ahoy.js"

import "controllers";

require("jquery")

window.$ = window.jQuery = jQuery;

document.addEventListener('DOMContentLoaded', () => {
  // right filter sidebar
  const sidebar = document.querySelector('#model_settings')
  if (sidebar) {
    sidebar.addEventListener('click', event => {
      const insideSection = document.querySelector('#settings_section')
      if (!(event.target === insideSection || insideSection.contains(event.target))) {
        sidebar.classList.toggle('settings_open')
      }
    })
  }
	
	const menuButton = document.querySelector('#site_title')
	const menu = document.querySelector('#tabs')

	menuButton.addEventListener('click', event => {
	  const logo = document.querySelector('#site_title_image')
	  const forbiddenLinks = event.target === logo ||
	      logo.contains(event.target) 
	  if (!forbiddenLinks) {
	    menu.classList.toggle('tabs_open')
	  }
	});
	
  // close left menu sidebar on any click outside
  document.body.addEventListener('click', event => {
    if (sidebar == null) return;
    const forbiddenLinks = event.target === sidebar ||
        sidebar.contains(event.target)
    if (sidebar.classList.contains('settings_open') && !forbiddenLinks) {
      sidebar.classList.remove('settings_open')
    }
  })
	
	$('div[contenteditable]').keydown(function(e) {
	    if (e.keyCode === 13) {
	        document.execCommand('insertHTML', false, "\n");
	        return false;
	    }
	});
	
	$('.gpt-model').change(function() {
		var message = ''
		if($(this).val()=='gpt-4o') {
			message = 'GPT-4 takes its time for excellence. It will take a minute.';
		} else {
			message = 'GPT-3.5 is refining your output. Stand by.';
		}
		$("#ui-id-1").text(message);
		$('#dialog-loader').attr('title', message);
	})
})

window.preview_kb_doc = function(url) {
  var ext = url.split('.').pop() 
  // https://gist.github.com/tzmartin/1cf85dc3d975f94cfddc04bc0dd399be#gistcomment-2310091
  if(ext == 'pptx' || ext == 'docx') {
    $('#file-preview-frame').attr('src','https://view.officeapps.live.com/op/embed.aspx?src=' + url)
  } else {
    $('#file-preview-frame').attr('src','https://docs.google.com/a/intentful.ai/viewer?url=' + url + '&embedded=true')
  }
  window.preview_dialog = $("#file-preview").dialog({
	  autoOpen: true,
	  height: 600,
	  width: 800,
	  modal: true,
	  buttons: {
	    Cancel: function() {
	      window.preview_dialog.dialog('close');
	    }
	  }
  });
}
