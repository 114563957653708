import { Controller } from "stimulus"
import { TIMEOUT_MESSAGES } from './messages.js'

export default class extends Controller {
  static targets = [ 
    "business_name", "location", "business_type",
    "fact_1", "fact_2", "fact_3", "word_count",
    "event_name", "event_location", "event_type", "event_date",
    "event_fact_1", "event_fact_2", "event_fact_3", "event_word_count",
    "activity_name", "activity_location", "activity_type", "activity_date",
    "activity_fact_1", "activity_fact_2", "activity_fact_3", "activity_word_count",
		"container_1", "container_2", "container_3"]
		
  static values = {
    url: String
  }
    
  initialize() {
    $(this.business_nameTarget).focus()
    this.index = 0
    this.answer = ""
  }
	
  typewriter_1() {
		if (this.index < this.answer.length) {
				this.container_1Target.innerHTML += this.answer.charAt(this.index);
		    this.index++;
		    setTimeout(this.typewriter_1.bind(this), 3);
		}
  }
  
  typewriter_2() {
		if (this.index < this.answer.length) {
				this.container_2Target.innerHTML += this.answer.charAt(this.index);
		    this.index++;
		    setTimeout(this.typewriter_2.bind(this), 3);
		}
  }
  
  typewriter_3() {
		if (this.index < this.answer.length) {
				this.container_3Target.innerHTML += this.answer.charAt(this.index);
		    this.index++;
		    setTimeout(this.typewriter_3.bind(this), 3);
		}
  }
	
  autoSize(event) {
	  var el = $(event.target)[0];
	  el.style.height = 0;
	  el.style.height = (el.scrollHeight) + "px";
  }
	
  clear(event) {
		$(event.target).closest('.inputs').find('input, textarea').not('input[type="submit"]').val('');
		$(event.target).closest('.inputs').find('input, textarea').first().focus();
		var id = $(event.target).closest('.inputs').attr('id').slice(-1);
		$("#container_" + id)[0].innerHTML = '';
		$("#counter_" + id).text('0 words');
		$([document.documentElement, document.body]).animate({
			scrollTop: $(event.target).closest('.inputs').offset().top
		}, 1000);  
  }
	
  copy(event) {
		var $temp = $("<textarea>");
		$("body").append($temp);
		$temp.val($(event.target).parent().nextAll('div:first').text()).select();
		document.execCommand("copy");
		$temp.remove();
		$('.flashes').show();
		setTimeout(() => { $('.flashes').hide()} , 2000);
  }
	
	countWords(s) {
		s = s.replace(/(^\s*)|(\s*$)/gi,"");
		s = s.replace(/[ ]{2,}/gi," ");
		s = s.replace(/\n /,"\n"); 
		return s.split(' ').filter(function(str){ return str!=""; }).length;
	}
	
	generate(event) {
		event.preventDefault();
		window.loader = $("#dialog-loader").dialog({
		    dialogClass: "no-close",
		    modal: true
		});
		setTimeout(() => { $('.ui-widget-overlay').css({opacity:'0.75'})}, 50);
		var input_data = { 
			authenticity_token: $('meta[name="csrf-token"]').attr('content'),
			model: $('input[name="model"]:checked').val(),
      apply_kb: $('input[name="content[apply_kb]"]:checked').val()
		}
		var fieldset = $(event.target).closest('.inputs').attr('id');
		switch (fieldset) {
			case "input-fields-1": input_data = {
				  ...input_data,
				  type: 1,
          business_name: this.business_nameTarget.value,
          location: this.locationTarget.value,
          business_type: this.business_typeTarget.value,
          fact_1: this.fact_1Target.value,
          fact_2: this.fact_2Target.value,
          fact_3: this.fact_3Target.value,
          word_count: this.word_countTarget.value,
				  content: this.container_1Target.textContent
				}
				break;
			case "input-fields-2": input_data = {
				  ...input_data,
				  type: 2,
          event_name: this.event_nameTarget.value,
          location: this.event_locationTarget.value,
          event_type: this.event_typeTarget.value,
          event_date: this.event_dateTarget.value,
          fact_1: this.event_fact_1Target.value,
          fact_2: this.event_fact_2Target.value,
          fact_3: this.event_fact_3Target.value,
          word_count: this.event_word_countTarget.value,
				  content: this.container_2Target.textContent
				}
				break;
			case "input-fields-3": input_data = {
				  ...input_data,
				  type: 3,
          activity_name: this.activity_nameTarget.value,
          location: this.activity_locationTarget.value,
          activity_type: this.activity_typeTarget.value,
          activity_date: this.activity_dateTarget.value,
          fact_1: this.activity_fact_1Target.value,
          fact_2: this.activity_fact_2Target.value,
          fact_3: this.activity_fact_3Target.value,
          word_count: this.activity_word_countTarget.value,
				  content: this.container_3Target.textContent
				}
				break;
		}
		$.ajax({
		  context: this,
		  async: true,
		  type: "POST",
		  url: this.urlValue,
		  data: input_data,
		  dataType: 'json',
		  timeout: 120000
		}).done(function(data) {
	  		window.loader.dialog('close');	
	  		if (typeof data[0] === 'number') {
					if(data[0] == 0)
	  				setTimeout(() => { alert("Smth went wrong. Please try again later! Error type:\n" + data[1])}, 500);
					else
						setTimeout(() => { alert(data[1])}, 500);
	  		} else { 
					if ($("#container_" + input_data.type).text().length == 0) {
						this.index = 0;
						this.answer = data[0] + "\n";
					} else {
						this.index = $("#container_" + input_data.type).text().length;
						this.answer += data[0] + "\n";
					}
					$("#counter_" + input_data.type).text(this.countWords(this.answer) + ' words');
					eval('this.typewriter_' + input_data.type + '()');
	  		}
		}).fail(function( jqXHR, textStatus ) {
	  	  window.loader.dialog('close');
				alert( TIMEOUT_MESSAGES[Math.floor(Math.random()*TIMEOUT_MESSAGES.length)] );
		});
	}
}